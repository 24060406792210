"use client";

import { cn } from "@peerigon/pupper/tailwind";
import { useWindowScroll } from "react-use";
import { colors } from "src/styles/variables";

type NavigationWrapperProps = {
	children: React.ReactNode;
};

const NavigationWrapper: React.FC<NavigationWrapperProps> = ({ children }) => {
	const { y } = useWindowScroll();

	const showFadeInScrollAnimation = y > 20;

	return (
		<header
			style={{
				"--grayLine": colors.gray.line,
			}}
			className={cn(
				"sticky top-0 z-header w-full bg-white p-6 shadow-header",
				"after:absolute after:left-0 after:top-0 after:z-[-1] after:h-full after:w-full after:opacity-0 after:shadow-[0_0_20px_var(--grayLine)] after:transition-opacity after:duration-1000 after:ease-in after:content-['']",
				showFadeInScrollAnimation && "after:opacity-100",
			)}
		>
			{children}
		</header>
	);
};

export default NavigationWrapper;
