"use client";

import { cn, tw } from "@peerigon/pupper/tailwind";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import CircledFaceDefault from "../../../assets/circledFaceDefault.svg";
import { dimensions } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import TitleDescription from "../../TitleDescription/TitleDescription";
import { ContentContainerTw } from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

export const ListOfProsSectionFragment = graphql(`
	fragment ListOfProsSectionItem on ListOfProsSection {
		id
		anchor
		sectionHeadline: headline
		introText: descriptionMd
		spacingTop
		spacingBottom
		listItems {
			id
			icon {
				...CmsImageFields
			}
			text
		}
		gridColumns
	}
`);

export const ListOfProsSection: React.FC<{
	section: FragmentType<typeof ListOfProsSectionFragment>;
}> = ({ section }) => {
	const {
		anchor,
		sectionHeadline,
		introText,
		listItems,
		spacingTop,
		spacingBottom,
		gridColumns,
	} = getFragmentData(ListOfProsSectionFragment, section);

	const gridClasses = {
		2: tw`md:grid-cols-[repeat(auto-fit,_minmax(400px,_1fr))]`,
		3: tw`md:grid-cols-[repeat(auto-fit,_minmax(300px,_1fr))]`,
		4: tw`md:grid-cols-[repeat(auto-fit,_minmax(240px,_1fr))]`,
	}[gridColumns ?? 2];

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<Anchor id={anchor} />
			<ContentContainerTw>
				<TitleDescription
					title={sectionHeadline}
					description={introText}
				/>
				<ul
					className={cn(
						"m-0 grid list-none grid-cols-1 items-baseline gap-px-30 p-0 sm:gap-px-60",
						gridClasses,
					)}
				>
					{listItems.map(({ id, text, icon }) => (
						<li
							key={id}
							className="m-auto flex w-full max-w-[110ch] flex-col items-center md:flex-row"
						>
							<div className="shrink-0">
								{icon ? (
									<CmsImage
										image={getFragmentData(
											CmsImageFields,
											icon,
										)}
										className="mb-px-10 w-px-60 md:mr-px-30"
									/>
								) : (
									<CircledFaceDefault
										className="mb-px-10 w-px-60 md:mr-px-30"
										width={dimensions.spacing.px60}
									/>
								)}
							</div>
							<div className="text-center text-almostBlack md:text-left">
								<Markdown source={text} />
							</div>
						</li>
					))}
				</ul>
			</ContentContainerTw>
		</BaseSection>
	);
};
