"use client";

import MobileNavigation from "src/components/Navigation/MobileNavigation";
import { useChangeLocale } from "src/components/Navigation/Navigation.hooks";
import NavigationWrapper from "src/components/Navigation/NavigationWrapper";
import DesktopNavigation from "./DesktopNavigation";
import { getFragmentData, graphql } from "../../__generated__";
import type { FragmentType } from "../../__generated__";

export type NavLink = { routeName: string; label: string };
type SortedNavLinks = {
	mainNavLinks: Array<NavLink>;
	secondaryNavLinks: Array<NavLink>;
};

type NavigationProps = {
	data: FragmentType<typeof HeaderFragment>;
	initialMobileMenuVisible?: boolean;
	disableLanguageSwitch?: boolean;
	localizedPathname: Record<string, Record<string, string>>;
};

export const HeaderFragment = graphql(`
	fragment HeaderFields on Header {
		items {
			... on HeaderItem {
				id
				title
				page {
					title
					shortTitle
					routeName
					icon {
						...CmsImageFields
					}
				}
				menuColumns {
					id
					title
					layout
					items {
						title
						description
						color
						icon {
							...CmsImageFields
						}
						page {
							title
							shortTitle
							routeName
							icon {
								...CmsImageFields
							}
						}
					}
				}
			}
		}
	}
`);

const Navigation: React.FC<NavigationProps> = ({
	data,
	initialMobileMenuVisible = false,
	disableLanguageSwitch,
	localizedPathname,
}) => {
	const dataFromFragment = getFragmentData(HeaderFragment, data);

	const { pathname, onChangeLocale } = useChangeLocale(localizedPathname);

	const { items } = dataFromFragment;

	const BLOG_HEADER_ITEM_ID = "clrhp034lu11k0ct7qm0ogy3a";
	const CONTACT_HEADER_ITEM_ID = "clrhp034ku11i0ct7h1lv41i5";

	const secondaryLinkIds = [BLOG_HEADER_ITEM_ID, CONTACT_HEADER_ITEM_ID];

	const { mainNavLinks, secondaryNavLinks } = items.reduce<SortedNavLinks>(
		(acc, item) => {
			if (secondaryLinkIds.includes(item.id)) {
				acc.secondaryNavLinks.push({
					routeName: item.page?.routeName ?? "",
					label: item.title,
				});
			} else {
				acc.mainNavLinks.push({
					routeName: item.page?.routeName ?? "",
					label: item.title,
				});
			}
			return acc;
		},
		{ mainNavLinks: [], secondaryNavLinks: [] },
	);

	return (
		<NavigationWrapper>
			<DesktopNavigation
				mainNavLinks={mainNavLinks}
				secondaryNavLinks={secondaryNavLinks}
				items={items}
				pathname={pathname}
				disableLanguageSwitch={disableLanguageSwitch}
				onChangeLocale={onChangeLocale}
			/>

			<MobileNavigation
				mainNavLinks={mainNavLinks}
				secondaryNavLinks={secondaryNavLinks}
				items={items}
				pathname={pathname}
				initialMobileMenuVisible={initialMobileMenuVisible}
				disableLanguageSwitch={disableLanguageSwitch}
				onChangeLocale={onChangeLocale}
			/>
		</NavigationWrapper>
	);
};

export default Navigation;
